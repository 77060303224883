<template>
  <b-container class="p-0" box>
    <b-card body-class="d-flex justify-content-center pt-5 pb-5">
      <b-form @submit.prevent="addPost" class="mt-5 mb-5">
        <b-row>
          <b-col cols="12" xl="5" lg="5" md="5" sm="12">
            <b-img :src="image" fluid thumbnail></b-img>
            <b-form-file v-model="$v.form.image.$model" class="mt-3 w-100 file-img" @change="onImageChange"
              accept="image/*" :class="{'is-invalid': $v.form.image.$error}" placeholder="Upload Image"></b-form-file>
            <span role="alert" class="invalid-feedback">
              <strong v-if="!$v.form.image.required">The image is required</strong>
              <strong v-if="!$v.form.image.isImage && $v.form.image.required">The file must be an image in png, jpg, jpeg or svg format</strong>
            </span>
            <!-- Featured -->
            <h6 class="d-block mt-3 mb-3 cursor-pointer" @click="form.featured = !form.featured">
              <vue-fontawesome icon="star" :style="styleStart"></vue-fontawesome>
              Mark as featured
            </h6>
          </b-col>
          <b-col cols="12" xl="7" lg="7" md="7" sm="12">
            <!-- Title -->
            <b-form-group id="input-group-1" label="Title *" label-for="input-1" class="mb-3">
              <b-form-input id="input-1" v-model="$v.form.title.$model" type="text" placeholder="Enter title"
                :class="{'is-invalid': $v.form.title.$error}"></b-form-input>
              <span role="alert" class="invalid-feedback">
                <strong v-if="!$v.form.title.required">The title is required</strong>
                <strong v-if="!$v.form.title.minLength">The title must have at least {{$v.form.title.$params.minLength.min}} letters</strong>
                <strong v-if="!$v.form.title.maxLength">The title must be less than {{$v.form.title.$params.maxLength.max}} letters</strong>
              </span>
            </b-form-group>
            <!-- Summary -->
            <b-form-group id="input-group-1" label="Summary" label-for="input-2" class="mb-3">
              <b-form-input id="input-2" v-model="$v.form.subtitle.$model" type="text"
                placeholder="Enter summary" :class="{'is-invalid': $v.form.subtitle.$error}"></b-form-input>
              <span role="alert" class="invalid-feedback">
                <strong v-if="!$v.form.subtitle.maxLength">The summary must be less than {{$v.form.subtitle.$params.maxLength.max}} letters</strong>
              </span>
            </b-form-group>
            <!-- Post Date -->
            <b-form-group id="input-group-1" label="Post date" label-for="input-2" class="mb-3">
              <b-form-input id="input-2" v-model="form.post_date" type="date" placeholder="Enter post date"></b-form-input>
            </b-form-group>
            <!-- Category -->
            <b-form-group label="Category *" label-for="category" class="mb-3">
                <b-form-select v-model="form.category_id" :options="categories" id="category" class="form-select"
                  :class="{'is-invalid': $v.form.category_id.$error}">
                  <template #first>
                    <b-form-select-option :value="null" disabled>Select an option</b-form-select-option>
                    <b-form-select-option value="new_category" class="fw-bold">Create a new category</b-form-select-option>
                  </template>
                </b-form-select>
              <span role="alert" class="invalid-feedback">
                <strong v-if="!$v.form.category_id.required">The category is required</strong>
              </span>
            </b-form-group>
            <!-- Website -->
            <b-form-group id="input-group-3" label="Website *" label-for="input-7" class="mb-3">
                <b-form-select v-model="form.project_id" :options="projects" id="input-7" class="form-select"
                  :class="{'is-invalid': $v.form.project_id.$error}">
                  <template #first>
                    <b-form-select-option :value="null" disabled>Select an option</b-form-select-option>
                  </template>
                </b-form-select>
              <span role="alert" class="invalid-feedback">
                <strong v-if="!$v.form.project_id.required">The website is required</strong>
              </span>
            </b-form-group>
            <!-- Author -->
            <b-form-group label="Author *" label-for="author" class="mb-3">
                <b-form-select v-model="form.author_id" :options="authors" id="author" class="form-select"
                  :class="{'is-invalid': $v.form.author_id.$error}">
                  <template #first>
                    <b-form-select-option :value="null" disabled>Select an option</b-form-select-option>
                    <b-form-select-option value="new_author" class="fw-bold">Create a new author</b-form-select-option>
                  </template>
                </b-form-select>
              <span role="alert" class="invalid-feedback">
                <strong v-if="!$v.form.category_id.required">The author is required</strong>
              </span>
            </b-form-group>

          </b-col>

          <b-col cols="12">
            <div class="accordion" role="tablist">
              <!-- Card Media Links -->
              <b-card no-body class="mb-3 card-media-links" :class="{'error' : $v.links.$error}">
                <b-card-header header-tag="header" class="px-4 d-flex" role="tab" v-b-toggle.accordion-2>
                  <label>Media links</label>
                  <vue-fontawesome icon="chevron-up" class="when-open"></vue-fontawesome>
                  <vue-fontawesome icon="chevron-down" class="when-closed"></vue-fontawesome>
                </b-card-header>
                <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-container>
                      <b-row>
                        <b-col cols="12">
                          <b-row id="container-links">
                            <b-col cols="12" md="6" class="mb-3 link" v-for="(input,index) in $v.links.$each.$iter" :key="index">
                              <b-row>
                                <b-col cols="12">
                                  <b-input-group>
                                    <div class="custom-link">
                                      <div class="logo-container">
                                        <b-avatar v-if="input.link.$model != ''" :src="addLogo(input.link.$model)" :text="addAvatar(input.link.$model)" square
                                        :class="'avatar-' + index"></b-avatar>
                                      </div>
                                      <div class="container-input">
                                        <div class="input-container" :class="{'form-control is-invalid': input.$error}">
                                          <b-form-input v-model.trim="input.link.$model" placeholder="Media article link"></b-form-input>
                                          <div class="pdf d-flex">
                                            <div :class="[input.$error ? 'mr-3' : 'mr-1']">
                                              <a v-if="input.link.$model" :href="input.link.$model" target="_blank">
                                                <vue-fontawesome icon="link"></vue-fontawesome>
                                              </a>
                                              <vue-fontawesome v-else icon="link"></vue-fontawesome>
                                            </div>
                                            <div @click="removeLink(index)" style="cursor: pointer;">
                                              <vue-fontawesome icon="trash"></vue-fontawesome>
                                            </div>
                                          </div>
                                        </div>
                                        <span role="alert" class="invalid-feedback">
                                          <strong v-if="!input.link.required">The link is required</strong>
                                          <strong v-if="!input.link.minLength">The link must have at least {{input.link.$params.minLength.min}} letters</strong>
                                          <strong v-if="!input.link.maxLength">The link must be less than {{input.link.$params.maxLength.max}} letters</strong>
                                        </span>
                                      </div>
                                    </div>
                                  </b-input-group>
                                </b-col>
                                <!-- <b-col cols="1" class="p-0 trash" v-show="index || ( !index && links.length > 1)">
                                  <div @click="removeLink(index)">
                                    <vue-fontawesome icon="trash"></vue-fontawesome>
                                  </div>
                                </b-col> -->
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col cols="12" class="d-flex justify-content-end">
                          <b-button @click="addLink()">Add Link</b-button>
                        </b-col>
                      </b-row>
                    </b-container>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <!-- Card SEO -->
              <b-card no-body class="card-seo"
                :class="{'error' : $v.form.meta_title.$error || $v.form.alt_image.$error || $v.form.slug.$error || $v.metaTags.$error || existsSlug}">
                <b-card-header header-tag="header" class="px-4 d-flex" role="tab" v-b-toggle.seo>
                  <label>SEO</label>
                  <vue-fontawesome icon="chevron-up" class="when-open"></vue-fontawesome>
                  <vue-fontawesome icon="chevron-down" class="when-closed"></vue-fontawesome>
                </b-card-header>
                <b-collapse id="seo" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-container class="px-0 px-md-4">
                      <b-row>
                        <!-- Meta title -->
                        <b-col cols="12" md="6">
                          <b-form-group label="Meta title *" label-for="meta-title" class="mb-3">
                            <b-form-input id="meta-title" type="text" placeholder="Enter meta title"
                              v-model="$v.form.meta_title.$model" :class="{'is-invalid': $v.form.meta_title.$error}"></b-form-input>
                            <span role="alert" class="invalid-feedback">
                              <strong v-if="!$v.form.meta_title.required">The meta title is required</strong>
                              <strong v-if="!$v.form.meta_title.minLength">The meta title must have at least {{$v.form.meta_title.$params.minLength.min}} letters</strong>
                              <strong v-if="!$v.form.meta_title.maxLength">The meta title must be less than {{$v.form.meta_title.$params.maxLength.max}} letters</strong>
                            </span>
                          </b-form-group>
                        </b-col>
                        <!-- Alt image -->
                        <b-col cols="12" md="6">
                          <b-form-group label="Alt image" label-for="meta-alt-image" class="mb-3">
                            <b-form-input id="meta-alt-image" type="text" placeholder="Enter alt image"
                              v-model.trim="$v.form.alt_image.$model" :class="{'is-invalid': $v.form.alt_image.$error}"></b-form-input>
                            <span role="alert" class="invalid-feedback">
                            <strong v-if="!$v.form.alt_image.required">The alt image is required</strong>
                            <strong v-if="!$v.form.alt_image.maxLength">The alt image must be less than {{$v.form.alt_image.$params.maxLength.max}} letters</strong>
                            </span>
                          </b-form-group>
                        </b-col>
                        <!-- Slug -->
                        <b-col cols="12">
                          <b-form-group label="Slug *" label-for="meta-slug" class="mb-3">
                            <b-form-input id="meta-slug" type="text" placeholder="example-post-slug"
                              v-model="$v.form.slug.$model" :class="{'is-invalid': $v.form.slug.$error || existsSlug}"
                              v-on:keyup="castTextSlug()"></b-form-input>
                            <span role="alert" class="invalid-feedback">
                              <strong v-if="!$v.form.slug.required">The slug is required</strong>
                              <strong v-if="!$v.form.slug.maxLength">The slug must be less than {{$v.form.slug.$params.maxLength.max}} letters</strong>
                              <strong v-if="existsSlug">This slug has already been taken</strong>
                            </span>
                            <span v-if="limitSlug" style="font-size: 14px;">
                              <strong class="text-danger">The slug exceeded the limit of accepted characters.</strong>
                            </span>
                          </b-form-group>
                        </b-col>
                        <!-- Meta Tags -->
                        <b-col cols="12" class="mt-3 meta-tags">
                          <label>Meta Tags</label>
                          <hr class="m-0">
                          <b-container class="px-0 px-xl-4 mt-3 mt-lg-4">
                            <b-row>
                              <b-col cols="12" v-for="(input, index) in $v.metaTags.$each.$iter" :key="index">
                                <b-container class="px-0 px-lg-4">
                                  <b-row>
                                    <b-col cols="12" md="6">
                                      <b-form-group label="Name *" label-for="meta-tag-name" class="mb-3">
                                        <b-form-input id="meta-tag-name" type="text" placeholder="Enter name"
                                          v-model.trim="input.name.$model" :class="{'is-invalid': input.name.$error}"></b-form-input>
                                          <span role="alert" class="invalid-feedback">
                                            <strong v-if="!input.name.required">The name is required</strong>
                                            <strong v-if="!input.name.minLength">The name must have at least {{input.name.$params.minLength.min}} letters</strong>
                                            <strong v-if="!input.name.maxLength">The name must be less than {{input.name.$params.maxLength.max}} letters</strong>
                                          </span>
                                      </b-form-group>
                                    </b-col>
                                    <b-col cols="12" md="6">
                                      <b-form-group label="Content *" label-for="meta-tag-content" class="mb-3 form-group-content">
                                        <div>
                                          <b-form-input id="meta-tag-content" type="text" placeholder="Enter content"
                                            v-model.trim="input.content.$model" :class="{'is-invalid': input.content.$error}"></b-form-input>
                                          <div class="trash" @click="removeMetaTag(index)" :style="index > 0 ? 'opacity: 1;' : 'opacity: 0; cursor: default;'">
                                            <vue-fontawesome icon="trash" :style="index > 0 ? '' : 'cursor: default;'"></vue-fontawesome>
                                          </div>
                                          <div class="plus" @click="addMetaTag()">
                                            <vue-fontawesome icon="plus"></vue-fontawesome>
                                          </div>
                                        </div>
                                        <div :class="{'is-invalid': input.content.$error}">
                                          <span role="alert" class="invalid-feedback">
                                            <strong v-if="!input.content.required">The content is required</strong>
                                            <strong v-if="!input.content.minLength">The content must have at least {{input.content.$params.minLength.min}} letters</strong>
                                            <strong v-if="!input.content.maxLength">The content must be less than {{input.content.$params.maxLength.max}} letters</strong>
                                          </span>
                                        </div>
                                      </b-form-group>
                                    </b-col>
                                  </b-row>
                                </b-container>
                              </b-col>
                            </b-row>
                          </b-container>
                        </b-col>
                      </b-row>
                    </b-container>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </b-col>

          <!-- Text Editor -->
          <!-- <b-col cols="12" class="mt-3">
            <VueEditor
              :editorOptions="editorSettings"
              :editorToolbar="toolbarOptions"
              v-model="$v.form.body.$model"
              :class="{'is-invalid': $v.form.body.$error}"
              ref="editor" class="code"/>
            <span role="alert" class="invalid-feedback">
              <strong v-if="!$v.form.body.required">The body is required</strong>
            </span>
          </b-col> -->

          <!-- Text editor Summernote -->
          <!-- <b-col cols="12" class="mt-3">
            <div id="summernote"></div>
            <div class="summernote-alert pt-2"></div>
          </b-col> -->

          <b-col cols="12" class="mt-3">
            <div class="richtexteditor rte-skin-rounded-corner rte-modern rte-toolbar-full rte-desktop rte-skin-gray"
              ref="neweditor" :class="{'error' : errorBody}">
            </div>
            <span role="alert" class="d-block invalid-feedback">
              <strong v-if="errorBody">The body is required</strong>
            </span>
          </b-col>

          <b-col cols="12" class="mt-3 d-md-flex justify-content-end buttons">
            <b-button type="submit" variant="primary" @click="form.status = false" class="mt-2 mt-md-0">Save as draft</b-button>
            <b-button type="submit" variant="primary" @click="form.status = true" class="mt-2 mt-md-0">Publish</b-button>
            <b-button class="preview mt-2 mt-md-0" variant="secondary" @click="previewModal()" :disabled="this.$v.form.$invalid">Preview</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>

    <b-modal v-model="modalPreview" hide-footer size="lg" body-class="body_modal_preview_post" header-class="header_modal_preview_post">
      <template #modal-header="{close}">
        <b-button variant="default" @click="close">
          <vue-fontawesome icon="angle-double-left" size="2"></vue-fontawesome>
        </b-button>
      </template>
      <b-tabs content-class="mt-3 pt-0 p-3" fill>
        <b-tab title="Preview article view" active>
          <div>
            <b-card class="container_press__column1--card">
              <div class="img-card"><b-img :src="image" fluid></b-img></div>
              <h4>{{ form.title }}</h4>
              <p>{{ form.subtitle }}</p>
            </b-card>
          </div>
        </b-tab>
        <b-tab title="Full article view">
          <div class="container_news_room_detail">
            <div class="container_news_room_detail__column_information">
              <b-row>
                  <b-col class="mt-3" cols="12" order-xl="1" order-lg="1" order-md="2" order-sm="2" order="2">
                      <h2 class="text-center mb-5">
                          {{form.title}}
                      </h2>
                      <h4 class="text-center mb-5">
                          {{form.subtitle}}
                      </h4>
                  </b-col>
                  <b-col class="mt-3" cols="12" order-xl="2" order-lg="2" order-md="1" order-sm="1" order="1">
                      <b-img-lazy :src="image" fluid center></b-img-lazy>
                  </b-col>
              </b-row>
              <b-row class="mt-lg-3">
                <b-col cols="12">
                  <small style="color: #6c757d;">{{form.post_date}}</small>
                </b-col>
              </b-row>
              <p v-html="form.body" class="mt-3 mb-5"></p>
              <hr>
              <div class="container_news_room_detail__column_information--featured_ons">
                  <a :href="value.link" v-for="(value, key) in links" :key="key" target="_blank">
                    <b-avatar :src="addLogo(value.link)" :text="addAvatar(value.link)"></b-avatar>
                  </a>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>

    <!-- Create Category -->
    <b-modal id="create-category" footer-class="d-none"
      body-class="p-0" size="md" v-model="showModalAddCategory">
      <template #modal-header="{ close }">
          <h5 class="mb-0">Create category</h5>
          <b-button size="sm" variant="default" @click="closeModalAddCategory()">
              <vue-fontawesome icon="times"></vue-fontawesome>
          </b-button>
      </template>
      <AddCategory v-on:category_id="selectCategory"/>
    </b-modal>

    <!-- Create Author -->
    <b-modal id="create-author" footer-class="d-none"
      body-class="p-0" size="md" v-model="showModalAddAuthor">
      <template #modal-header="{ close }">
          <h5 class="mb-0">Create author</h5>
          <b-button size="sm" variant="default" @click="closeModalAddAuthor()">
              <vue-fontawesome icon="times"></vue-fontawesome>
          </b-button>
      </template>
      <AddAuthor v-on:author_id="selectAuthor"/>
    </b-modal>

    <b-overlay :show="load" rounded="sm" no-wrap></b-overlay>
  </b-container>
</template>

<script>
import AddCategory from '@/views/Dashboard/Category/Add';
import AddAuthor from '@/views/Dashboard/Author/Add';
import { required, email, minLength, maxLength} from 'vuelidate/lib/validators';

// import { VueEditor, Quill } from "vue2-editor";
// import htmlEditButton from "quill-html-edit-button";
// import BlotFormatter from 'quill-blot-formatter';

// let Font = Quill.import('attributors/class/font');
// Font.whitelist = ['roboto','bebas','nunito','serif', 'monospace'];
// Quill.register(Font, true);

// Quill.register('modules/htmlEditButton', htmlEditButton);
// Quill.register('modules/blotFormatter', BlotFormatter);

export default {
  name: 'add_article',
  components: {
    // VueEditor,
    AddCategory,
    AddAuthor
  },
  metaInfo(){
    return {
      title: 'Add New Post',
      titleTemplate: '%s | Do Genius On'
    }
  },
  data() {
    return {
      toolbarOptions: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ header: 1 }, { header: 2 }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ size: ['small', 'normal', 'large', 'huge'] }],
        [{ align: [] }],
        [
          { color: [
            "#000000", "#e60000", "#ff9900", "#ffff00", "#008a00",
            "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc",
            "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb",
            "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0",
            "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200",
            "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000",
            "#663d00", "#666600", "#003700", "#002966", "#3d1466",
            'custom-color'
          ] },
          { background: [
            "#000000", "#e60000", "#ff9900", "#ffff00", "#008a00",
            "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc",
            "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb",
            "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0",
            "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200",
            "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000",
            "#663d00", "#666600", "#003700", "#002966", "#3d1466",
            'custom-color'
          ] }
        ],
        [{ list: 'ordered'}, { list: 'bullet'}, { list: 'check'}],
        [{ indent: '-1'}, { indent: '+1' }],
        // [{ font: Font.whitelist }],
        ['clean'],
        ['blockquote', 'code-block'],
        ['link', 'image', 'video']
      ],
      editorSettings: {
        modules: {
          htmlEditButton: {
            msg: "Edit the content in HTML format",
            buttonHTML: "&lt;HTML/&gt;",
          },
          // blotFormatter: {},
        },
      },
      form: {
        title: null,
        subtitle: String(),
        image: null,
        post_date: '',
        category_id: null,
        project_id: null,
        author_id: null,
        websites: null,
        body: null,
        featured: false,
        status: null,
        meta_title: String(),
        alt_image: String(),
        slug: String(),
      },
      image: require('@/assets/img/image.solid.svg'),
      logoDefault: require('@/assets/img/logo_not_found.png'),
      load: false,
      modalPreview: false,
      projects: [],
      categories: [],
      authors: [],
      featured_ons: [],
      links: [{ link: String() }],
      metaTags: [
        { name: 'description', content: String() }
      ],
      richEditor: null,
      errorBody: false,
      existsSlug: false,
      showModalAddCategory: false,
      showModalAddAuthor: false
    }
  },
  validations: {
    form: {
      title: { required, minLength: minLength(2), maxLength: maxLength(400) },
      subtitle: { maxLength: maxLength(400) },
      image: {
        required,
        isImage: function(val){
          if (val) {
            if ((val.type).includes("image/jpg") || (val.type).includes("image/png") ||
                (val.type).includes("image/svg") || (val.type).includes("image/jpeg") ||
                (val.type).includes("image/webp")) {
              return true;
            }
          }
          return false
        }
       },
      category_id: { required },
      project_id: { required },
      author_id: { required },
      // body: { required },
      meta_title: { required, minLength: minLength(5), maxLength: maxLength(255) },
      alt_image: { required, maxLength: maxLength(255) },
      slug: { required, maxLength: maxLength(255) },
    },
    links: {
      required,
      $each: {
        link: { required, minLength: minLength(2), maxLength: maxLength(255) }
      }
    },
    metaTags: {
      required,
      $each: {
        name: { required, minLength: minLength(2), maxLength: maxLength(255) },
        content: { required, minLength: minLength(2), maxLength: maxLength(255) }
      }
    }
  },
  mounted(){
    this.getProjects();
    this.getCategories();
    this.getAuthors();
    this.getFeaturedOns();
    // this.summernote();
    // this.customColor();
    // this.handleImage();

    this.richTextEditor();
  },
  methods: {
    addPost(){
      this.$v.form.$reset();
      this.$v.form.$touch();

      if (this.links.length > 0) {
        this.$v.links.$reset();
        this.$v.links.$touch();
      } else {
        this.$v.links.$reset();
      }

      if (this.metaTags.length > 0) {
        this.$v.metaTags.$reset();
        this.$v.metaTags.$touch();
      } else {
        this.$v.metaTags.$reset();
      }

      if (!this.$v.form.$invalid && !this.$v.links.$error && !this.$v.metaTags.$error) {
        if(this.richEditor.getHTMLCode()) {
          this.form.body = this.richEditor.getHTMLCode();
          // if ($('#summernote').summernote('code') != '<p><br></p>' && $('#summernote').summernote('code').length > 0){
            this.load = true;
            const formData = new FormData();
            formData.append('title', this.form.title);
            formData.append('subtitle', this.form.subtitle);
            formData.append('image', this.form.image);
            formData.append('body', this.form.body);
            // formData.append('body', $('#summernote').summernote('code'));
            formData.append('featured', this.form.featured);
            formData.append('post_date', this.form.post_date);
            formData.append('category_id', this.form.category_id);
            formData.append('project_id', this.form.project_id);
            formData.append('author_id', this.form.author_id);
            formData.append('status', this.form.status);
            formData.append('meta_title', this.form.meta_title);
            formData.append('alt_image', this.form.alt_image);
            formData.append('slug', this.form.slug);
            formData.append('links', JSON.stringify(this.links));

            for (let i = 0; i < this.metaTags.length; i++) {
              for (let key of Object.keys(this.metaTags[i])) {
                formData.append(`meta_tags[${i}][${key}]`, this.metaTags[i][key]);
              }
            }

            this.$http.post('post', formData, { headers: { Authorization: `Bearer ${this.$session.get('jwt')}` } }).then((response) => {
              if (response.status === 200) {
                this.$notify({ group: 'app', text: response.body.message, type: 'success' });
                this.$router.push({ name: 'posts.index' });
              }
              this.load = false;
            }, (error) => {
              if (error.status == 400) {
                if (error.body.response == 'error_form') {
                  this.$notify({ group: 'app', text: error.data.message, type: 'error' });
                } else if (error.body.response == 'error_slug') {
                  this.existsSlug = true;
                }
              }
              if (error.status == 500) {
                this.$notify({ group: 'app', text: 'An error has occurred, please try again.', type: 'error' });
              }
              this.load = false;
            });
          // } else {
          //   $('.summernote-alert').html('<small class="text-danger fw-bold">The body is required</small>');
          //   this.$notify({ group: 'app', text: 'All required fields must be filled in', type: 'error' });
          // }

        } else {
          this.errorBody = true;
          this.$notify({ group: 'app', text: 'All required fields must be filled in', type: 'error' });
        }
      } else {
        this.$notify({ group: 'app', text: 'All required fields must be filled in', type: 'error' });
      }
    },
    getProjects(){
      this.$http.get('project', {headers: {"Authorization": "Bearer "+this.$session.get('jwt')}}).then((response) => {
        if (response.status === 200) {
          this.projects = response.body.data;
        }
      }, (error) => {
        console.log('Error', error)
      })
    },
    getCategories(){
      this.$http.get('category', {headers: {"Authorization": "Bearer "+this.$session.get('jwt')}}).then((response) => {
        if (response.status === 200) {
          this.categories = response.body.data;
        }
      }, (error) => {
        console.log('Error', error)
      })
    },
    getAuthors(){
      this.$http.get('author', {headers: {"Authorization": "Bearer "+this.$session.get('jwt')}}).then((response) => {
        if (response.status === 200) {
          this.authors = response.body.data;
        }
      }, (error) => {
        console.log('Error', error)
      })
    },
    selectCategory(category_id) {
      this.getCategories();
      this.form.category_id = category_id;
    },
    selectAuthor(author_id) {
      this.getAuthors();
      this.form.author_id = author_id;
    },
    getFeaturedOns(){
      this.$http.get('featured_ons', {headers: {"Authorization": "Bearer "+this.$session.get('jwt')}}).then((response) => {
        if (response.status === 200) {
          this.featured_ons = response.body.data;
        }
      }, (error) => {
        console.log('Error', error);
      })
    },
    onImageChange(e){
      const file = e.target.files[0];
      if ((file.type).includes("image/jpg") || (file.type).includes("image/png") ||
          (file.type).includes("image/svg") || (file.type).includes("image/jpeg") || (file.type).includes("image/webp")) {
        this.image = URL.createObjectURL(file);
      }
    },
    addLink() {
      this.links.push({link: String()});
    },
    removeLink(index) {
      this.links.splice(index, 1);
    },
    addMetaTag() {
      this.metaTags.push({ name: String(), content: String() });
    },
    removeMetaTag(index) {
      if(index > 0) { this.metaTags.splice(index, 1); }
    },
    createSlug() {
      this.form.slug = this.form.title.replace(/[^a-zA-Z0-9]/g, '-').toLowerCase();
    },
    castTextSlug() {
      this.form.slug = this.form.slug.replace(/[^a-zA-Z0-9]/g, '-').toLowerCase();
    },
    summernote() {
      $('#summernote').summernote({
        height: 250,
        toolbar: [
          ['style', ['style']],
          ['font', ['bold', 'underline', 'clear']],
          ['color', ['color']],
          ['para', ['ul', 'ol', 'paragraph']],
          ['table', ['table']],
          ['insert', ['link', 'picture', 'video']],
          ['view', ['fullscreen', 'codeview']]
        ]
      });

      $('#summernote').summernote('code', null);
    },
    handleImage() {
      this.$refs.editor.quill.getModule('toolbar').addHandler('image', (value) => {
        if(value) {
          let value = prompt('What is the image URL');
          if(value){
            this.$refs.editor.quill.insertEmbed(null, 'image', value, Quill.sources.USER);
          }
        }
      });
    },
    customColor() {
      this.$refs.editor.quill.getModule('toolbar').addHandler('color', (value) => {
        if (value == 'custom-color') {
          value = prompt('Enter Hex/RGB/RGBA');
        }
        this.$refs.editor.quill.format('color', value);
      });

      this.$refs.editor.quill.getModule('toolbar').addHandler('background', (value) => {
        if (value == 'custom-color') {
          value = prompt('Enter Hex/RGB/RGBA');
        }
        this.$refs.editor.quill.format('background', value);
      });
    },
    closeModalAddCategory() {
      this.form.category_id = null;
      this.$bvModal.hide('create-category');
    },
    closeModalAddAuthor() {
      this.form.author_id = null;
      this.$bvModal.hide('create-author');
    },
    richTextEditor() {
      let editorConfigs = {
        skin: "gray",
        toolbar: "default",
        toolbarMobile: 'default',
        showPlusButton: false,
        galleryImages: [
          require('@/assets/img/image.solid.svg'),
          require('@/assets/img/person.png'),
          require('@/assets/img/smile.svg')
        ],
      }
      this.richEditor = new RichTextEditor(this.$refs.neweditor, editorConfigs);
    },
    previewModal() {
      this.form.body = this.richEditor.getHTMLCode();
      this.modalPreview = true;
    }
  },
  watch:{
    'form.category_id': function(value) {
      if(value === 'new_category') {
        this.showModalAddCategory = true;
      } else {
        this.showModalAddCategory = false;
      }
    },
    'form.author_id': function(value) {
      if(value === 'new_author') {
        this.showModalAddAuthor = true;
      } else {
        this.showModalAddAuthor = false;
      }
    },
  },
  computed: {
    styleStart(){
      return this.form.featured ? {color: '#FFDC32'} : '';
    },
    limitSlug() {
      if(this.form.slug.length > 60){
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
